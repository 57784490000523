import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import LogoCard from "./logo-card"
import PropTypes from "prop-types"

const BlockDownladableImages = ({ data }) => {
  return (
    <div className="col-span-3 mt-14 grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
      {data?.images &&
        data.images.map((image) => (
          <LogoCard
            key={image.id}
            downloadable
            image={
              <GatsbyImage
                className="h-full"
                image={getImage(image.localFile)}
                alt={image?.alternativeText || ""}
              />
            }
          />
        ))}
    </div>
  )
}

BlockDownladableImages.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        localFile: PropTypes.any.isRequired,
        alternativeText: PropTypes.string,
      })
    ),
  }),
}

export default BlockDownladableImages
