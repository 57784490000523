import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import BlocksRenderer from "../components/press-blocks-renderer"
import Seo from "../components/seo"
import BaseHeader from "../components/base-header"
import BaseSection from "../components/base-section"
import BaseSocialShare from "../components/social/base-social-share"
import BaseBreadCrumbs from "../components/blog/base-breadcrumbs"
import BaseBtn from "../components/base-btn"
import BlockDownladableImages from "../components/block-downloadable-images"
import { useDownloadImages } from "../hooks/useDownloadImages"
import BaseSectionTitle from "../components/base-section-title"

const PressPostPage = ({ data, location }) => {
  const article = data.strapiPress
  const defaultSeo = article.defaultSeo
  const seo = {
    metaTitle: defaultSeo?.metaTitle || article.title,
    metaDescription: defaultSeo?.metaDescription || article.description,
    shareImage: defaultSeo?.metaDescription || article.cover,
    url: article.slug,
  }
  const { downloadImages } = useDownloadImages({
    fileName: "Imagenes_nota_de_prensa",
  })
  const imgBlocks = article.blocks.find(
    (block) =>
      block.__typename === "STRAPI__COMPONENT_SHARED_DOWNLOADABLE_IMAGES"
  )
  const descriptionBlock = article.description.split(/\n/)

  return (
    <Layout as="article">
      <Seo seo={seo} />
      <BaseBreadCrumbs location={location} title={article.title} />

      <BaseHeader className="flex flex-wrap justify-between">
        <BaseSectionTitle>{article.title}</BaseSectionTitle>
        <div className="flex flex-col items-end justify-end">
          <span className="flex-shrink-0 text-brand-primary">
            {article.createdAt}
          </span>
        </div>
      </BaseHeader>

      <BaseSection>
        <GatsbyImage
          image={getImage(article?.cover?.localFile)}
          alt={article?.cover?.alternativeText || ""}
          className="w-full lg:h-[900px] xl:w-[85rem]"
        />
        <div className="mt-4 flex flex-col items-end justify-end">
          <span className="text-brand-primary">{article.createdAt}</span>
        </div>
        <div className="px-4 lg:px-20">
          <BaseSectionTitle level={2} className="mt-8 w-full lg:w-[75%]">
            {article.subtitle}
          </BaseSectionTitle>
          <BaseSectionTitle
            level={3}
            className="mt-8 w-full text-brand-primary"
          >
            {descriptionBlock.map((paragraph, i) => (
              <span key={i} className="mb-3 block">
                {paragraph}
              </span>
            ))}
          </BaseSectionTitle>
          <BlocksRenderer blocks={article.blocks || []} />

          <BaseSocialShare quote={article.title} url={location.href} />
        </div>
      </BaseSection>

      <BaseSection>
        <div className="grid grid-cols-3 md:mx-14">
          <div className="col-span-3 md:col-span-3 lg:col-span-2">
            <h2 className="voltio-subtitle">
              Imágenes nota <br />
              de prensa
            </h2>
          </div>
          <div className="col-span-3 font-medium md:col-span-3 md:ml-0 md:mt-10 lg:col-span-1 lg:ml-14 lg:mt-0">
            Si prefieres, puedes descargarlas todas con un sólo click.
            <BaseBtn
              onClick={() => downloadImages()}
              className="mt-6 sm:w-full"
            >
              Descargar todas las imágenes
            </BaseBtn>
          </div>
          <BlockDownladableImages data={imgBlocks} />
        </div>
      </BaseSection>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiPress(slug: { eq: $slug }) {
      id
      slug
      title
      subtitle
      description
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          id
          url
          localFile {
            url
          }
        }
      }
      blocks {
        ...PressBlocks
      }
      createdAt: publishedDate(
        formatString: "[Madrid, ] DD [de] MMMM YYYY"
        locale: "ES"
      )
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default PressPostPage
